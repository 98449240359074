// VideoWorkRedirect.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ScriptsRedirect = () => {
  let navigate = useNavigate();

  useEffect(() => {
    window.location.href = 'https://mega.nz/folder/ZadlyShC#QTbmsmGba8HH1OwE-33jxQ';
  }, [navigate]);

  return null;
};

export default ScriptsRedirect;
